import messageList from "./list"
import messageCategory from "./category"
import messageHandle from "./handle-message"
import systemSet from "./systemSet"
import labelList from "./labelList"
import mesTemplet from "./mesTemplet"
import mesManagement from "./mesManagement";
import mesAdd from "./add-mes";
import manAdd from "./man-add"

const message = {
    path: '/message',
    component: resolve => require(['@/pages/admin/message'], resolve),
    redirect: '/message/list',
    children: [
        messageList,
        messageCategory,
        messageHandle,
        systemSet,
        labelList,
        mesTemplet,
        mesManagement,
        mesAdd,
        manAdd
    ],
    meta: {
        requireAuth: true
    }
}

export default message